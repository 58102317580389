<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          src="@/assets/images/logo/newlogo_dark_text.png"
          alt="logo"
          style="width: 135px"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          v-if="isTokenValid"
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ pageText.heading }} Password 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ pageText.body }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group
                :label="pageText.password1"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="user.password"
                      maxlength="15"
                      :disabled="isDisabled"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                :label="pageText.password2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="user.cPassword"
                      maxlength="15"
                      :disabled="isDisabled"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
                :disabled="isDisabled"
              >
                {{ pageText.Button }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>

        <b-col
          v-if="!isTokenValid"
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Password {{ pageText.heading }} Link Expired 🔓
          </b-card-title>
          <b-card-text class="mb-2">
            <p>We're sorry, but the password {{ pageText.heading }} link you clicked has expired. Password {{ pageText.heading }} links are valid for a limited time for security reasons.
              If you still need to {{ pageText.heading }} your password, please follow these steps:</p>
            <p>1. Visit the password {{ pageText.heading }} page: Go to our password {{ pageText.heading }} page and to initiate a new password {{ pageText.heading }} request.</p>
            <p>2. Check your email: Once you submit the password {{ pageText.heading }} request, you will receive a new email with a fresh password {{ pageText.heading }} link.</p>
            <p>If you require any assistance or have further questions, please contact our support team at info@bisevo.se.</p>
            <p>Thank you for your understanding.</p>
          </b-card-text>
          <p class="text-center mt-2">
            <b-link
              :to="{name:'auth-forgot-password'}"
              target="_blank"
            >
              <feather-icon icon="ChevronLeftIcon" /> Forgot Password
            </b-link>
          </p>
        </b-col>

      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import { mapActions } from 'vuex'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: {
        id: '',
        cPassword: '',
        password: '',
      },
      isTokenValid: false,
      isDisabled: false,
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      required,
      password1FieldType: 'password',
      password2FieldType: 'password',
      pageText: {
        heading: 'Reset',
        body: 'Your password should include a minimum of 8 characters and must consist of at least one uppercase letter, one lowercase letter, one special character, and one digit and new password must be different from previously used passwords',
        Button: 'Set New Password',
        password1: 'New Password',
        password2: 'Confirm Password',
      },

    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    const {
      successMessage, errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
    }
  },
  created() {
    this.validateToken()
  },
  mounted() {
    if (router.currentRoute.params.id) {
      if (router.currentRoute.query.objectID === '0') {
        this.pageText = {
          heading: 'Set',
          body: 'Your password should include a minimum of 8 characters and must consist of at least one uppercase letter, one lowercase letter, one special character, and one digit.',
          Button: 'Set Password',
          password1: 'Password',
          password2: 'Confirm Password',
        }
      }
    }
  },
  methods: {
    ...mapActions('user', ['resetPassword', 'fetchResetPassword']),
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validateToken() {
      if (router.currentRoute.params.id) {
        const { id } = router.currentRoute.params
        this.fetchResetPassword(id).then(response => {
          if (response) {
            this.isTokenValid = true
          }
        })
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (router.currentRoute.params.id) {
            this.user.id = router.currentRoute.params.id

            this.resetPassword(this.user)
              .then(() => {
                this.isDisabled = true
                this.successMessage('Your password was updated. You can now return to the app and use your new password to login.')
                this.$router.push({ name: 'auth-login' })
              })
              .catch(error => {
                if (error.response && error.response.data && error.response.data.status === 'failure') {
                  this.errorMessage(error.response.data.message.toString())
                } else {
                  this.errorMessage('An unexpected error occurred. Please try again later.')
                }
              })
          }
        }
      }).catch(() => {
        this.errorMessage('Form validation failed. Please check your input.')
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
